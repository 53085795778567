import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  VERIFICATION_SUCCESS,
  LOGOUT,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
  LOGIN_SETTINGS,
} from '../actions/types'

const initialState = {
  user: localStorage.profile ? JSON.parse(localStorage.profile) : '',
  isAuthenticated: localStorage.profile ? true : false,
  loginSettings: { isSalesForceRefresh: '', mapped: '', crmUserType: '' },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      }

    case LOGIN_SETTINGS:
      return {
        ...state,
        loginSettings: action.payload,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        user: action.payload,
      }
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        user: { ...state.user, profile_image: action.payload.url },
      }

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case LOGOUT:
      localStorage.clear()
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }

    case VERIFICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      }

    default: {
      return state
    }
  }
}
