import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Protected = ({ isLoggedIn, children }) => {
  const { mapped, isSalesForceRefresh, crmUserType } = useSelector((state) => state.auth.loginSettings)
  if (!isLoggedIn) {
    return <Navigate to="/" replace />
  }
  if (crmUserType === 'secondary') {
    return children
  }
  if (mapped === false || isSalesForceRefresh === false) {
    return <Navigate to="/configuration" replace />
  }
  return children
}

Protected.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default Protected
