import { TOAST_SHOW, TOAST_HIDE } from '../actions/types'

const initialState = {
    show: false,
    settings: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TOAST_SHOW: 
        return {
            ...state,
            show: true,
            settings: action.payload
          }
        break;
        case TOAST_HIDE: 
        return {
            ...state,
            show: false,
            settings: {}
          }
        break;
        default: {
            return state
          }
    }
}