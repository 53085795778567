export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const LOGIN_SETTINGS = 'LOGIN_SETTINGS'

export const LOGOUT = 'LOGOUT'

export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'

export const LOADER_SHOW = 'LOADER_SHOW'
export const LOADER_HIDE = 'LOADER_HIDE'
export const LOADER_TOGGLE = 'LOADER_TOGGLE'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const MANAGE_HEADER = 'MANAGE_HEADER'
export const TOAST_SHOW = 'TOAST_SHOW'
export const TOAST_HIDE = 'TOAST_HIDE'


export const CONFIG_STEP = 'CONFIG_STEP'

export const PIPELINE_DATA = 'PIPELINE_DATA'
export const PIPELINE_FILTER = 'PIPELINE_FILTER'
export const UPDATE_PIPELINE_FILTER = 'UPDATE_PIPELINE_FILTER'

export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE'

export const CRM_CONFIGURATION = 'CRM_CONFIGURATION'