import { createStore, combineReducers } from 'redux'
import authReducers from './reducers/authReducer'
import loaderReducer from './reducers/loaderReducer'
import sidebarReducer from './reducers/sidebarReducer'
import toastReducer from './reducers/toastReducer'
import configurationReducer from './reducers/configurationReducer'
import pipelineReducer from './reducers/pipelineReducer'
import crmconfigurationReducer from './reducers/crmconfigurationReducer'

const rootReducers = combineReducers({
  auth: authReducers,
  loader: loaderReducer,
  sidebar: sidebarReducer,
  toast: toastReducer,
  config: configurationReducer,
  pipeline: pipelineReducer,
  configuration: crmconfigurationReducer,
})

const store = createStore(rootReducers)

export default store
