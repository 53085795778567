import React, { Suspense, useRef, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CSpinner, CToast, CToastBody, CButton, CToastClose, CToaster } from '@coreui/react'
import './scss/style.scss'
import { useSelector } from 'react-redux'
import Protected from './Protected'
import CommonData from './CommonData'
import CIcon from '@coreui/icons-react'
import { cilClock } from '@coreui/icons'

const Loading = (
  <div className="bg-light min-vh-100 d-flex flex-column align-items-center">
    <div className={`loader-container`}>
      <CSpinner color="light" />
    </div>
  </div>
)

const Toasts = () => {
  const toastData = useSelector((state) => state.toast)
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  let color
  if (toastData.settings?.value === 'new') {
    color = 'warning'
  } else if (toastData.settings.success) {
    color = 'success'
  } else {
    color = 'danger'
  }
  const exampleToast = (
    <CToast autohide={true} color={color} className="text-white align-items-center">
      <div className="d-flex t-container align-items-center">
        {toastData.settings?.value === 'new' ? (
          <CIcon icon={cilClock} size="xl" className="cilClock custom-margin" />
        ) : (
          <img
            src={toastData.settings.success ? '/icons/toast-tick.svg' : '/icons/toast-error.svg'}
            alt="tick"
          />
        )}
        <div>
          <div>
            <CToastBody className="toast-head">
              {toastData.settings.success ? 'Success' : 'Error'}
            </CToastBody>
            <CToastBody className="toast-message">{toastData.settings.message}</CToastBody>
          </div>
          <CToastClose component={CButton} color="secondary" size="sm" className="ms-1">
            <img src="/icons/toast-close.svg" alt="tick" />
          </CToastClose>
        </div>
      </div>
    </CToast>
  )
  useEffect(() => {
    if (toastData.show && toastData.settings.message) {
      addToast(exampleToast)
    }
  }, [toastData])
  return (
    <>
      <CToaster ref={toaster} push={toast} placement="top-end" />
    </>
  )
}

const Loader = () => {
  const loader = useSelector((state) => state.loader.loaderShow)
  return (
    <div className={`loader-container ${loader === true ? '' : 'd-none'}`}>
      <CSpinner color="light" />
    </div>
  )
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Login'))
const SignUp = React.lazy(() => import('./views/pages/SignUp'))
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'))
const Configure = React.lazy(() => import('./views/pages/Configuration'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))
const ConfirmEmail = React.lazy(() => import('./views/pages/ConfirmEmail'))

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated)
  const { mapped, isSalesForceRefresh } = useSelector((state) => state.auth.loginSettings)

  return (
    <BrowserRouter>
      <Suspense fallback={Loading}>
        <Loader />
        <Toasts />
        <CommonData isLoggedIn={isLoggedIn} />
        <Routes>
          <Route path="/confirm-email" exact element={<ConfirmEmail />} />
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={isLoggedIn ? <Configure /> : <Login />} />
          <Route path="/configuration" exact element={isLoggedIn ? <Configure /> : <Login />} />
          <Route
            path="/configuration/:step"
            exact
            element={isLoggedIn ? <Configure /> : <Login />}
          />

          <Route path="/sign-up" exact element={isLoggedIn ? <Configure /> : <SignUp />} />
          <Route
            path="/forgot-password"
            exact
            element={isLoggedIn ? <Configure /> : <ForgotPassword />}
          />
          <Route
            path="/reset-password"
            exact
            element={isLoggedIn ? <Configure /> : <ResetPassword />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            element={isLoggedIn ? <Configure /> : <Page404 />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            element={isLoggedIn ? <Configure /> : <Page500 />}
          />
          <Route
            path="*"
            name="Home"
            element={
              <Protected isLoggedIn={isLoggedIn}>
                <DefaultLayout />
              </Protected>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
