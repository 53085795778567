import { PIPELINE_DATA, PIPELINE_FILTER, UPDATE_PIPELINE_FILTER } from '../actions/types'

const initialState = {
  data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PIPELINE_DATA :
        return {
            ...state,
            overview: action.payload
          }
        case PIPELINE_FILTER : 
        return {
            ...state,
            appliedFilter: action.payload
        }
        case UPDATE_PIPELINE_FILTER :
            // console.log('payloaddd', action.payload)
            return {
                ...state,
                overview: {...state.overview, ...action.payload.data}
            }

        default: {
            return state
        }
    }
}