import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetData, PostData } from './apiHandler'
import {
  LOGIN_SETTINGS,
  CONFIG_STEP,
  TOAST_SHOW,
  LOADER_SHOW,
  LOADER_HIDE,
  LOGOUT,
} from './redux/actions/types'

const CommonData = ({ isLoggedIn }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryParameters = new URLSearchParams(window.location.search)
  const code = queryParameters.get('code')

  const getCommonData = async () => {
    if (!localStorage.getItem('commonData')) {
      PostData('data/common_data', { password: 'Mobikasa@123', ip: '' }).then((response) => {
        localStorage.setItem('commonData', JSON.stringify(response.data))
      })
    }
  }

  const getConfig = (s = '') => {
    GetData('sforce/getconfig').then((response) => {
      if (response.success === true) {
        const data = response.data
        const configData = { 2: data }
        if (!localStorage.getItem('config')) {
          localStorage.setItem('config', JSON.stringify(response.data))
        } else {
          localStorage.setItem('config', JSON.stringify(response.data))
        }
        dispatch({
          type: LOGIN_SETTINGS,
          payload: {
            isSalesForceRefresh: data.isSalesForceRefresh,
            mapped: data.mapped,
            crmUserType: data.crmUserType,
            syncStatus: data?.data_sync_status,
          },
        })
        if (data.crmUserType === 'secondary') {
          dispatch({ type: LOADER_HIDE })
          navigate('/pipeline/overview')
          return
        }
        if (
          data.mapped === true &&
          data.isSalesForceRefresh === true &&
          (s === 2 ||
            window.location.pathname === '/' ||
            window.location.pathname.split('/').includes('configuration'))
        ) {
          navigate('/pipeline/overview')
          dispatch({ type: LOADER_HIDE })
          return
        }
        if (data.mapped === true && data.isSalesForceRefresh === false) {
          //dispatch({ type: LOADER_HIDE })
          window.location = process.env.REACT_APP_API_URL + 'sforce/services/ext/oauth'
          return
        }
        if (data.mapped === false) {
          dispatch({ type: LOADER_HIDE })
          dispatch({ type: CONFIG_STEP, payload: { step: s ? s : 1, data: configData } })
          navigate('/configuration/crm-salesforce')
          return
        }
        dispatch({ type: LOADER_HIDE })
        let step = 1
        dispatch({ type: CONFIG_STEP, payload: { step: step, data: configData } })
      } else {
        dispatch({ type: LOADER_HIDE })
        dispatch({ type: TOAST_SHOW, payload: { message: response?.message, success: false } })
      }
    })
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (code) {
        async function checkLogin() {
          dispatch({ type: LOADER_SHOW })
          const response = await GetData(
            'sforce/linksforce?sfaccessToken=' + encodeURIComponent(code),
          )
          if (response.success === true) {
            window.history.pushState({}, document.title, window.location.pathname)
            getConfig(2)
          } else {
            dispatch({ type: LOADER_HIDE })
            dispatch({ type: TOAST_SHOW, payload: { message: response?.message, success: false } })
            dispatch({ type: LOGOUT })
            navigate('/')
          }
        }
        checkLogin()
      } else {
        getConfig()
      }
    }
    getCommonData()
  }, [isLoggedIn])

  return <></>
}

export default CommonData
