import { CRM_CONFIGURATION } from '../actions/types'
const initialState = {
  data: {}
}
export default function (state = initialState, action) {
  switch (action.type) {
    case CRM_CONFIGURATION:
      return {
        ...state,
        configurationData: action.payload
      }
    default: {
      return state
    }
  }

}