import { CONFIG_STEP } from '../actions/types'

const initialState = {
  step: localStorage.step ? localStorage.step : 1,
  stepData: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CONFIG_STEP : 
            localStorage.setItem('step', action.payload.step)
        return {
            ...state,
            ...{
                step: action.payload.step, 
                stepData: 
                    action.payload.data ? 
                            Object.keys(action.payload.data).length === 0 ? 
                            {} 
                        :
                            {...state.stepData,...action.payload.data} 
                    : 
                    state.stepData
                }
          }
        

        default: {
            return state
        }
    }
}